<template>
  <div class="">
    <b-overlay :show="loading"> </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay } from "bootstrap-vue";

export default {
  data() {
    return {
      token: this.$route.params.token,
      loading: false,
    };
  },
  components: {
    BOverlay,
  },
  mounted() {
    this.loading = true;
    let token = this.token;

    this.$store
      .dispatch("auth/verifikasiEmail", token)
      .then((res) => {
        this.loading = false;
        this.$router.replace("/login").then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Berhasil`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Verifikasi email sukses, Silahkan Login!!`,
            },
          });
        });
        // this.$route.push("/login");
      })
      .catch((err) => {
        this.displayError(err);
        this.loading = false;
        this.$router.replace("/register").then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "XIcon",
              variant: "danger",
              text: `Verifikasi email bermasalah`,
            },
          });
        });
      });
  },
};
</script>

<style></style>
